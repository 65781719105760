<template>
    <div id="product_wrap">
        <!-- 详情页顶部导航栏 -->
        <detailNav></detailNav>

        <div class="content flex">
            <div class="photos flex-y">
                <div class="swiper-container gallery-top flex-1">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide w100 h100" :style="{ backgroundImage : 'url(' + picUrl + ')'}"></div>
                    </div>
                </div>
                <div class="gallery-thumbs flex">
                    <div class="swiper-wrapper flex align-center">
                        <div class="swiper-slide" v-for="(item, index) in imgList" :key="index">
                            <img :src="item | imagePath" @mouseover="imageHover(item)">
                        </div>
                    </div>
                </div>
                <div>
                    产品货号：{{product.productSn}}
                    <template v-if="this.iscoller===true">
                        <div class="shoucang" @click="deletecoller(product.id,product.storeId)">
                            <img src="../../assets/image/shoucang.png" alt="">
                            <span>取消收藏</span>
                        </div>
                    </template>
                    <template v-else>
                        <div class="shoucang" @click="addcoller(product.id,product.storeId)">
                            <img src="../../assets/image/shoucang.png" alt="">
                            <span>收藏产品</span>
                        </div>
                    </template>

                </div>
            </div>
            <div class="detail_info">
                <div class="product_name" v-text="product.productName"></div>
                <div class="product_price flex">
                    <div class="left_label">起售价</div>
                    <!-- 公共产品 -->
                    <div class="right_content" v-show="!product.type">
                        {{product.startPrice | price(2)}}
                        <span v-show="product.commonProductUnit!=''">/{{product.commonProductUnit}}</span>
                    </div>

                    <!-- 自定义产品定价产品 -->
                        <div class="right_content" v-show="product.type && product.isBargain=='0'">{{product.startPrice | price(2)}}元
                            <span v-show="product.selfDefProductUnitPrice">/{{product.selfDefProductUnitPrice}}</span>
                        </div>
<span></span>
                        <!-- 自定义产品议价 -->
                        <div class="right_content" v-show="product.type && product.isBargain=='1'">价格和商家商定</div>

                </div>
                <div class="language">{{product.promotion}}</div>


                <div class="price_way flex">
                    <div class="left_label">报价方式</div>
                    <div class="right_content flex">
                        <div :class="[active  === 1 ? 'btn_s_d' : 'btn_o_d']" @click="changeTab(1)">在线议价</div>
                        <template v-if="isShow">
                            <div :class="[active === 2 ? 'btn_s_d' : 'btn_o_d']" @click="changeTab(2)">系统定价</div>
                        </template>
                    </div>
                </div>

                <!-- 在线议价区域 -->
                <transition name="el-fade-in-linear">
                    <!-- 在线议价区域 -->
                    <div v-show="active === 1">
                        <div class="server flex">
                            <div class="left_label"></div>
                            <div class="right_content flex align-center">
                                <!-- <a
                                        class="service flex align-center"
                                        v-for="(item, index) in store.storeCustomerServiceList"
                                        :key="index"
                                        target="_blank"
                                        :href="'http://wpa.qq.com/msgrd?v=3&uin='+item.qq+'&site=qq&menu=yes'"
                                >
                                    <a></a>
                                    客服:{{item.nickName}}
                                </a> -->

                            </div>
                        </div>
                        <div class="server flex">
                            <div class="left_label">订单金额</div>
                            <div class="right_content">
                                <el-input
                                        maxlength="7"
                                        minlength="1"
                                        v-model="finalPrice"
                                        placeholder="请输入与商家协商后的价格"
                                        style="width: 220px;height: 30px"
                                        @blur="blurHandler"
                                        @focus="inputError = false;inputError2 = false"
                                        @input="oninput"
                                ></el-input>
                                <label for class="gray">元</label>
                                <span style="margin-left:20px;color:#ff0000;font-size: 13px" v-show="inputError">*金额格式输入不正确</span>
                                <span style="margin-left:20px;color:#ff0000;font-size: 13px" v-show="inputError2">*订单金额需大于1元</span>
                            </div>
                        </div>
                        <!-- <div class="notify flex align-center">
                            <i class="el-icon-warning"></i>
                            请与商家客服沟通后，输入双方确定的价格
                        </div> -->
                        <div class="server flex" style="width:60%;" v-if="0">
                            <div class="left_label">买家留言</div>
                            <el-input
                                    type="textarea"
                                    :rows="4"
                                    maxlength="200"
                                    placeholder="请填写订单商品具体规格，数量及其他说明"
                                    v-model="remark"
                            ></el-input>
                        </div>
                    </div>
                </transition>

                <!-- 系统定价区域 -->
                <transition name="el-fade-in-linear">
                    <div v-show="active === 2">
                        <div class="server flex" v-for="(item, index) in product.productAttrList" :key="index">
                            <div class="left_label" v-text="item.attrName"></div>
                            <div class="right_content">
                                <el-radio-group
                                        size="small"
                                        v-model="selectVal[index].label"
                                        @change="changeSku($event, index, item.attrValueList)"
                                >
                                    <el-radio-button
                                            :label="sku.label"
                                            :value="sku.value"
                                            v-for="(sku, inx) in item.attrValueList"
                                            :key="inx"
                                            :disabled="sku.disabled"
                                            style="margin-top:5px;"
                                    ></el-radio-button>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="server flex">
                            <div class="left_label">购买数量</div>
                            <div class="right_content">
                                <el-input-number size="mini" v-model="number" :step="1" :min="1"></el-input-number>
                            </div>
                        </div>
                        <div class="server flex">
                            <div class="left_label">总计价格</div>
                            <div class="price-content">
                                <label>￥{{this.price * this.number | price}}</label>
                            </div>
                        </div>
                    </div>
                </transition>

                <div class="btnGroup flex">
                    <div class="left_label"></div>
                    <div class="right_content flex">
                        <button
                                class="down"
                                @click="order"
                                :disabled="orderEnable()"
                                :class="{disable: orderEnable()}"
                        >立即下单
                        </button>
                        <div class="add_cart" @click="addCart" v-if="0">加入购物车</div>
                    </div>
                </div>
                <div class="pay_way">
          <span>
            支付方式：
            <a class="weixin"></a>微信支付
            <a class="pay"></a>支付宝
          </span>
                    <!-- <span class="seven">
                      服务承诺：
                      <a class="promise"></a>七天无理由退货
                    </span>-->
                </div>
            </div>

            <Service/>
        </div>

        <!--商家信息-->
        <div class="shopping flex">
            <!-- <BusinessInfo :storeId="storeID"></BusinessInfo> -->
            <div class="detail">

                <div class="detailsArea">
                    <div class="mucontents">
                        <div class="mucontents-top">
                            <h2>{{store.storeName}}</h2>
                            <div class="btnWrap">
                                <div @click="goshop()">进入店铺</div>
                                <div class="collection" @click="collection" v-if="!isCollect">
                                收藏店铺
                                </div>
                                <div class="collection1" @click="collection1" v-if="isCollect">
                                取消收藏
                                </div>
                            </div>

                            <div class="goodAt">
                                <p>擅长服务</p>
                                <div class="goodAtList" >

                                    <span v-for="(item,i) in product.serviceList" @click="goshop()">{{item}}</span>
                                </div>
                            </div>
                        </div>
                        <!--本店推荐-->
                        <div class="mucontents-bottom">
                            <h2>本店推荐</h2>
                            <div class="recommendedWrap">
                                <div
                                        class="recommended"
                                        v-for="(item, index) in topList"
                                        :key="index"
                                        v-if='index <= 10'
                                        @click="viewItem(item)"
                                >
                                    <img :src="item.pcUrl | imagePath">
                                    <div class="name" v-text="item.productName"></div>
                                    <div class="price">￥{{item.lowerPrice | price}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="detailWrap">
                        <div class="flex align-center tab">
                            <span @click="changeNav(1)" :class="[navIndex === 1 ? 'active' : '']">产品详情</span>
                            <!-- <span @click="changeNav(2)" :class="[navIndex === 2 ? 'active' : '']">累计评价(125)</span>
                            <span @click="changeNav(3)" :class="[navIndex === 3 ? 'active' : '']">产品提问(20)</span> -->
                        </div>
                        <div class="pic" v-html="product.productDes"></div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue'
    import {mapGetters, mapMutations} from 'vuex'
    // import BusinessInfo from '@/components/common/businessInfo'
    import detailNav from '@/components/common1/detailNav'
    import Service from '@/components/common1/service'


    export default {
        components: {detailNav,Service},
        data() {
            return {
                isCollect:"", //是否收藏了店铺
                finalPrice: 0,
                number: 1,
                remark: '',
                product: {},
                imgList: [],
                picUrl: '',
                active: 1,
                navIndex: 1,
                selectVal: [],
                sku: '',
                price: 0,
                storeId: '',
                enableList: [],
                inputError: false,
                topList: [], //推荐商品
                isShow: true,//订单金额是否显示
                inputError2: false,//金额输入错误提示
                iscoller: false,
                storeID:this.$route.query.sid, //父组件传递的店铺id
            };
        },

        destroyed(){
            document.getElementsByClassName("search_content-wrap")[0].style.display="block";
        },
        computed: {
            ...mapGetters(['user', 'store'])
        },
        mounted() {
            const {query} = this.$route;
            const {sid, pid} = query;
            this.storeId = sid;
            this.init(sid, pid);
            this.queryProduct(sid, pid);

            //进入产品详情页就不显示公共组件头部
            document.getElementsByClassName("search_content-wrap")[0].style.display="none";

        },
        methods: {
        collection(){ //点击收藏店铺
            this.isCollect=true;
            this.$http.get('collectStore/add', { params: { storeId: this.storeId } }).then(res => {
                if(res.data.code=="0"){
                    this.$message({
                    message: '收藏店铺成功!',
                    type: 'success'
                    })
                }
            })
        },
        collection1(){ //取消收藏
            this.isCollect=false;
            this.$http.get('collectStore/deleteByStoreId', { params: { storeId: this.storeId } }).then(res => {
                if(res.data.code=="0"){
                    this.$message({
                    message: '取消收藏店铺成功!',
                    type: 'success'
                    })
                }
            })
        },
        goshop(){ //详情页进店铺
            this.$router.push({
                name:"shopPage",
                params:{
                id:this.store.id
                }
            })
        },
            oninput(e) {
        // 通过正则过滤小数点后两位
    this.finalPrice = (this.finalPrice.match(/^\d*(\.?\d{0,2})/g)[0]) || null
    },
            ...mapMutations({orderEntry: 'ORDER_ENTRY', saveProduct: 'ORDER_PRODUCT'}),

            init(sid, pid) {
                this.$http.get(this.$API.queryDetail, {params: {productId: pid, storeId: sid}}).then(res => {
                    const {data} = res.data;
                    this.product = data;
                    this.isCollect=data.collect;
                    let {productAttrList, attrIndexPriceList} = data;
                    productAttrList.map((item, inx) => {
                        this.selectVal.push({label: '', value: -1});
                        item.attrValueList = item.attrValueList.map((c, i) => {
                            return {label: c, value: i, disabled: inx === 0 ? this.transformFirstState(i) : true}
                        });
                        return item
                    });
                    this.product.productAttrList = productAttrList;
                    if (productAttrList.length <= 0) {//产品没有属性默认为系统议价
                        this.isShow = false;
                        this.changeTab(1);
                    }
                    this.imgList=[];
                    this.filterPrice();
                    this.imgList.push(data.img2, data.img3, data.img4, data.img5)
                   console.log( this.imgList,123456)
                    if (data.img3==undefined) {
                        this.imgList=this.imgList.splice(0,1)
                    }
                    else if (data.img4==undefined) {
                        this.imgList=this.imgList.splice(0,2)
                    }
                    else if (data.img5==undefined) {
                        this.imgList=this.imgList.splice(0,3)
                    }

                    this.picUrl = Vue.filter('imagePath')(data.img1)
                })
            },
            queryProduct(sid, pid) {
                this.$http.get(this.$API.queryProduct, {params: {storeId: sid, productId: pid}}).then(res => {
                    const {data} = res.data.data
                    const {isCollect} = res.data.data
                    this.topList = data
                    this.iscoller = isCollect
                    // console.log(this.iscoller)

                })
            },
            /**
             * @description: 立即下单
             * @param {type}
             * @return:
             */
            order() {
                let fastList ={
                fastFlag:"1"
                }
                localStorage.setItem("fastList",JSON.stringify(fastList));

                const list = this.product.attrIndexPriceList.filter(ele => ele.priceIndex === this.sku);
                const skuId = list.length > 0 ? list[0]['skuId'] : '';
                if (this.active * 1 === 2) {
                    this.product['startPrice'] = this.finalPrice;
                    this.product.productQuantity = this.number;
                }
                this.product['skuId'] = skuId;
                this.orderEntry(1);
                const {startPrice, productQuantity, img1, productName, productSn, id} = this.product;
                const active = this.active;
                const goods = {
                    id,
                    productPicUrl: img1,
                    productName: productName,
                    productSn,
                    productPrice: this.price,
                    productQuantity: this.number,
                    skuId: skuId
                };
                if (active === 1 || active === "1") {//买家走议价
                    if (this.finalPrice <= 1) {//输入的金额
                        this.inputError2 = true;//显示提示框
                        return;
                    }
                    goods.skuId = "";//走议价skuId初始化 这个不能为0
                    goods.productQuantity = 1;//数量为1
                    goods.productPrice = this.finalPrice;//订单价格为议价价格
                }
                this.saveProduct(goods)
                this.$router.push({name: 'order'})
            },
            /**
             * @description: 加入购物车
             * @param {type}
             * @return:
             */
            addCart() {
                const list = this.product.attrIndexPriceList.filter(ele => ele.priceIndex === this.sku)
                const skuId = list.length > 0 ? list[0]['skuId'] : ''
                const param = {
                    buyerId: this.user.id,
                    nickName: this.user.userName,
                    number: this.number,
                    productId: skuId * 1 > 0 ? `p${skuId}` : this.$route.query.pid,
                    storeId: this.$route.query.sid,
                    price: this.active === 1 ? this.number * this.product.startPrice : this.finalPrice
                }
                this.$http.post(this.$API.ADDCART, [param]).then(res => {
                    this.$router.push({name: 'shoppingCart'})
                })
            },
            /**
             * @description: 根据选中规格筛选当前价格
             * @param {type}
             * @return:
             */
            filterPrice() {
                const list = this.product.attrIndexPriceList.filter(item => item.priceIndex === this.sku)
                const item = list.length > 0 ? list[0] : {price: this.product.startPrice}
                const {price} = item
                this.price = price
            },
            // 默认在线议价
            changeTab(index) {
                this.active = index
            },
            /**
             * @description: 切换规格
             * @param {type}
             * @return:
             */
            changeSku(e, index, list) {
                console.log(list,88888)
                console.log(index)
                const value = list.findIndex(item => item.label === e)
                this.selectVal[index]['value'] = value
                this.selectVal.map((item, i) => {
                    item = i > index ? Object.assign(item, {value: -1, label: ''}) : item
                    return item
                })
                const price = this.selectVal
                    .filter(item => item.value >= 0)
                    .map(item => item.value)
                    .join('-')
                this.sku = price
                this.filterPrice()
                this.filterEnable(index, value)
            },
            /**
             * @description: 判断规则是否可点击
             * @param
             * @return:
             */
            filterEnable(index, value) {
                const {attrIndexPriceList, productAttrList} = this.product
                const result = attrIndexPriceList.map(item => item.priceIndex)
                const attrList = this.product.productAttrList[index + 1]
                if (attrList) {
                    this.product.productAttrList[index + 1]['attrValueList'].map((item, c) => {
                        item.disabled = !result.some(item => item.startsWith(this.sku + '-' + c))
                        return item
                    })
                }
            },
            imageHover(item) {
                this.picUrl = Vue.filter('imagePath')(item)
            },
            changeNav(index) {
                this.navIndex = index
            },
            transformState(index, inx) {
                return this.enableList[index].indexOf(inx) < 0
            },
            transformFirstState(inx) {
                const items = this.product.attrIndexPriceList.map(item => item.priceIndex)
                return !items.some(item => item.startsWith(inx))
            },
            viewItem(item) {
                // console.log(444)
                const {id} = item
                this.init(this.storeId, id)
                window.scrollTo(0,0);
            },
            orderEnable() {
                if (this.active === 2
                ) {
                    const {productAttrList} = this.product
                    if (productAttrList) {
                        const skuList = this.sku.split('-').filter(item => item.length > 0)
                        return skuList.length !== productAttrList.length
                    } else {
                        return false
                    }
                } else {
                    return this.$check.trim(this.finalPrice + '').length === 0
                }
            },
            blurHandler() {
                if (!this.$check.checkMoney(this.finalPrice)) {
                    this.inputError = true
                }
            },
            /**
             * @description: 产品收藏
             * @param
             *@return:
             * */
            addcoller(id, storeId) {
                this.$http.get('/collectProduct/add', {params: {productId: id, storeId: storeId}}).then(res => {
                    if (res.data.code === 0) {
                        this.$message({
                            message: '收藏成功!',
                            type: 'success'
                        });
                        this.iscoller=true
                    }
                })
            },
            deletecoller(id,storeId){
                this.$http.get('/collectProduct/deleteByStoreIdAndProductId', {params: {productId: id, storeId: storeId}}).then(res=>{
                    if (res.data.code === 0) {
                        this.$message({
                            message: '成功取消收藏!',
                            type: 'success'
                        });
                        this.iscoller=false
                    }
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '../../assets/scss/change.scss';

    .gray {
        margin-left: 10px;
        color: #999;
    }

    .swiper-container {
        width: 100%;
        height: 400px;
        min-height: 400px;
        margin-left: auto;
        margin-right: auto;

        .swiper-wrapper {
            height: 100%;
        }
    }

    .swiper-slide {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        img {
            width: 100%;
            height: 100%;
            cursor: pointer;
            background: rgba(125, 125, 125, 0.1);
        }
    }

    .gallery-top {
        width: 100%;
    }

    .gallery-thumbs {
        height: 20%;
        box-sizing: border-box;
        padding: 10px 0;
        box-sizing: border;
    }

    .gallery-thumbs .swiper-slide {
        width: 23%;
        height: 100%;
        margin: 4px;
        background: rgba(255, 255, 255, 1);
        padding: 5px;
        box-sizing: border-box;
    }

    .gallery-thumbs .swiper-slide-thumb-active {
        opacity: 1;
    }

    .detail {
        width: 100%;
        margin-bottom: 20px;
        overflow: hidden;



        span {
            display: inline-block;
            margin: 0 10px;
            cursor: pointer;
        }
        .detailsArea{
            display: flex;
            width: 1200px;
            margin: 0 auto;
            -webkit-user-select:none;
            -moz-user-select:none;
            -ms-user-select:none;
            user-select:none;
            .mucontents{
                width: 220px;
                margin-right: 20px;
                .mucontents-top{
                    border: 1px solid #e5e5e5;
                        h2{
                            line-height: 40px;
                            padding-left: 10px;
                            font-size: 14px;
                            color: #666;
                            border-top: 1px solid #efefef;
                            border-bottom: 1px solid #efefef;
                            background: #f5f5f5;
                        }
                        .btnWrap{
                            display: flex;
                            justify-content: space-around;
                            align-items: center;
                            height:56px;
                            >div{
                                cursor:pointer;
                                width: 90px;
                                height: 28px;
                                font-size: 14px;
                                text-align: center;
                                line-height: 28px;
                            }
                            >:nth-child(1){
                                background: #333;
                                color: #fff;
                            }
                            >:nth-child(2){
                                border: 1px solid #efefef;
                                color: #333;
                            }
                            .collection{
                                border: 1px solid #d82229;
                                color: #d82229;
                                // background: #d82229

                            }
                            .collection1{
                                border: 1px solid #d2d2d2;
                                color: #999;
                            }
                        }
                        .goodAt{
                            margin-top: 4px;
                            padding-bottom: 10px;

                            p{
                                line-height: 30px;
                                font-size: 14px;
                                color: #666;
                                padding: 0 10px;
                            }
                            .goodAtList{
                                display: flex;
                                flex-wrap: wrap;
                                padding-left: 10px;
                                span{
                                    display: block;
                                    margin-left: 0;
                                    margin-right: 11px;
                                    margin-bottom: 10px;
                                    font-size: 12px;
                                    color:#999;
                                    border: 1px #e5e5e5 solid;
                                    padding: 4px;
                                }
                            }
                        }
                    }
                    .mucontents-bottom{
                        display: flex;
                        flex-direction: column;
                        margin-top: 20px;
                        border: 1px solid #efefef;
                        h2{
                            line-height: 40px;
                            padding-left: 10px;
                            font-size: 14px;
                            color: #666;
                            border-top: 1px solid #efefef;
                            border-bottom: 1px solid #efefef;
                            background: #f5f5f5;
                        }
                    }

                }
                .detailWrap{
                    width: 960px;
                    .tab{
                        background: #f5f5f5;
                        height: 40px;
                        border-bottom: 1px solid #d82229;
                        >:first-child{
                            margin-left: 0;
                        }
                    }
                    .pic {
                        text-align: center;
                        // padding: 30px;
                        box-sizing: border-box;
                        width: 100%;
                        // background-color: #ddd;
                        margin-top: 20px;
                    }

                    .flex > span {
                        padding: 0 10px;
                        box-sizing: border-box;
                        text-align: center;
                        line-height: 40px;
                        height: 40px;
                        // border-bottom: 1px solid #ccc;

                        &.active {
                            background: #d82229;
                            color: white;
                            border-bottom: none;
                        }

                        // &:not(:first-child) {
                        //     display: none;
                        // }
                    }
                }

        }

    }

    /deep/ .content {
        max-width: 1200px;
        width: 100%;
        height: auto;
        margin: 30px auto;
        border-bottom: 1px solid #e5e5e5;
        padding-bottom: 30px;

        .photos {
            width: 400px;
            height: 100%;

            > div {
                &:nth-child(2) {
                    width: 100%;
                    justify-content: space-between;
                }

                &:last-child {
                    width: 100%;
                    text-align: left;
                    margin: 10px 0;
                    font-size: 14px;

                    > div {
                        display: inline-block;
                        height: 20px;
                        text-align: center;
                        margin: 0 5px;
                        vertical-align: top;
                        img {
                            margin-right: 5px;
                        }

                    }
                }
            }
        }

        .detail_info {
            width: 550px;
            height: 100%;
            margin-left: 20px;

            .product_name {
                text-align: left;
                font-size: 24px;
                font-weight: bold;
                margin: 10px 0;
            }

            .product_price {
                width: 100%;
                height: 50px;
                line-height: 50px;
                background-color: #e6f7ef;
                margin-bottom: 15px;
                background: #f5f5f5;

                .right_content {
                    color: #d82229;
                    font-size: 18px;
                    font-weight: bold;
                }
            }

            .language{
                margin-bottom: 15px;
                color:#d82229;
                // font-size: 14px;
                font-weight: 900;
                padding-left: 15px;
                font-size: 14px;
            }

            .price-content {
                font-size: 16px;
                color: #fe0036;

                label {
                    font-size: 24px;
                }
            }

            .price_way {
                line-height: 30px;
                margin-bottom: 15px;
                text-align: center;
                font-size: 14px;

                .btn_s_d,
                .btn_o_d {
                    width: 110px;
                    height: 30px;
                    cursor: pointer;
                }

                .btn_s_d {
                    background-color: #d82229;
                    color: #d82229;
                    color: #fff;
                }

                .btn_o_d {
                    border: 1px dashed #ddd;
                    color: #ccc;
                }
            }

            .server {
                line-height: 40px;
                margin-bottom: 15px;

                .right_content {
                    > div {
                        color: white;
                        cursor: pointer;
                    }

                    > .service {
                        width: 150px;
                        text-align: center;
                        background-color: #ff9600;
                        color: #fff;
                        text-decoration: none;
                        margin-right: 10px;

                        a {
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            background: url('../../assets/image/server.png') no-repeat center;
                            margin-right: 10px;
                            margin-left: 10px;
                        }
                    }
                }
            }

            .btnGroup {
                width: 100%;
                height: 40px;
                margin-top: 30px;
                text-align: center;

                .down,
                .add_cart {
                    width: 150px;
                    height: 40px;
                    line-height: 40px;
                    cursor: pointer;
                    outline: none;
                    font-size: 18px;
                    border: none;

                    &.disable {
                        background: #cccccc;
                    }
                }

                .down {
                    background-color: #d82229;
                    color: white;
                }

                .add_cart {
                    border: 1px solid #d82229;
                    box-sizing: border-box;
                    margin-left: 20px;
                    color: #d82229;
                }
            }

            .left_label {
                width: 100px;
                min-width: 100px;
                color: black;
                font-size: 16px;
                text-align: left;
                padding-left: 15px;
                font-size: 14px;
            }

            .notify {
                width: 100%;
                font-size: 14px;
                color: #ff9801;
                margin-bottom: 15px;
                text-align: left;
                margin-left: 10px;

                i {
                    margin: 0 10px 0 6px;
                }

                a {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                    // vertical-align: middle;
                    background: url('../../assets/image/tishi.png') no-repeat center;
                }
            }

            .pay_way {
                width: 100%;
                font-size: 14px;
                text-align: left;
                margin-top: 30px;
                padding-left: 15px;

                > span {
                    a {
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        vertical-align: middle;
                        margin: 0 6px;
                    }

                    .weixin {
                        background: url('../../assets/image/weixin.png') no-repeat center;
                        background-size: contain;
                    }

                    .pay {
                        background: url('../../assets/image/zhifubao.png') no-repeat center;
                        background-size: contain;
                    }

                    .promise {
                        background: url('../../assets/image/qitian.png') no-repeat center;
                        background-size: contain;
                    }
                }

                .seven {
                    margin-left: 30px;
                }
            }
        }
    }


    .recommendedWrap {
        overflow: hidden;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        cursor: pointer;
    }

    .recommended {
        margin: 0 10px 20px;
        box-sizing: border-box;
        min-width: 160px;

        img {
            width: 100%;
            height: 160px;
            padding: 10px;
            // background: rgba(205, 123, 100, 0.4);
        }

        font-size: 14px;
        text-align: left;

        .name {
            color: #000;
            margin: 10px 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .price {
            color: #d82229;
            font-weight: bold;
        }
    }


    //以下按钮颜色重置样式
    /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        background: #d82229;
        color: #fff;
        border-color: #d82229;
        webkit-box-shadow: none;
        box-shadow: none;
    }

  //按钮划过重置样式
  /deep/ .el-radio-button__inner{
        &:hover{
            color: #606266;
        }
    }
/deep/ .is-disabled .el-radio-button__inner{
    &:hover{
        color: #c0c4cc;
    }
}

//element button-radio双击出现蓝色阴影
/deep/ .el-radio-button__inner{
  -moz-user-select:none;/*火狐*/
  -webkit-user-select:none;/*webkit浏览器*/
  -ms-user-select:none;/*IE10*/
  /*-khtml-user-select:none;!*早期浏览器*!*/
  user-select:none;
}

/deep/ .el-tabs__item:focus.is-active.is-focus:not(:active) {
    -webkit-box-shadow: none !important;
    box-shadow: 1px;
  }


</style>
