<template>
    <div class="service">
        <div class="btnWrap">
            <div class="storeShop">{{store.storeName}}</div>
            <a
                 class="contact"
                v-for="(item, index) in store.storeCustomerServiceList"
                :key="index"
                target="_blank"
                :href="'http://wpa.qq.com/msgrd?v=3&uin='+item.qq+'&site=qq&menu=yes'"
                v-show="store.storeCustomerServiceList.length!=0"
            >
                <img src="../../assets/icon/qq.png" alt="">
                <span>
                    店铺QQ
                    <!-- {{item.nickName}} -->
                </span>

            </a>
             <a
                class="contact1"
                v-show="store.storeCustomerServiceList.length==0 || store.storeCustomerServiceList==undefined"
                @click="nullqq"
            >
                <img src="../../assets/icon/qq.png" alt="">
                <span>
                    店铺QQ
                </span>
            </a>

            <!-- <button type="button">444</button> -->
<!--            <a class="phone" @click="dialogVisible = true">-->
<!--                <img src="../../assets/icon/dianhua.png" alt="">-->
<!--                <span>电话联系</span>-->
<!--                -->
<!--            </a>-->

        </div>
        <div class="info">
            <h2>孔雀提醒</h2>
            <p>请走平台线上交易，勿线下交易</p>
            <p>下单前请确认与商家协商价格一</p>
            <p>致。确认收货后将赠送您等额任</p>
            <p>选礼品。</p>
        </div>

        <div class="phonePop">
            <el-dialog title="" :visible.sync="dialogVisible">
                <div class="popTop">
                <p class="storeName">{{store.storeShortName}}</p>
                <div class="storeMobile">
                <div>
                    <img src="../../assets/image/phone.png" alt="" align="middle">
                    <span>{{store.mobile}}</span>
                </div>
                <div class="streFooter">
                    注：为了您的交易安全，请走孔雀有礼线上交易如遇可疑商家，可及时向平台投诉举报！
                </div>
                </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
export default {
    name:"service",
    data() {
        return {
             dialogVisible: false,
        };
    },
    computed: {
        ...mapGetters(['user', 'store'])
    },
    created() {
        console.log(this.store.storeCustomerServiceList.length,7)
    },
    methods: {
        nullqq(){
             this.$message({
            message: '商家暂未添加qq客服，请点击电话联系商家哦!',
            type: 'warning'
            });
        }

    },
    }
</script>
<style lang='scss' scoped>
.service{
    width: 190px;
    height: 335px;
    margin-left: 38px;
    border-radius: 4px;
    background: #d82229;
    margin-top: 51px;
    .btnWrap{
        width: 180px;
        height: 175px;
        background: #fff;
        margin: 10px 5px 0 5px;
        display:flex;
        flex-direction: column;
        border-radius: 3px;
            padding: 0 20px;
         .storeShop{
             width: 140px;
             overflow: hidden;
             text-overflow: ellipsis;
             white-space: nowrap;
            height:60px;
            line-height: 60px;
            color: #333;
        }
        .contact,.contact1{
            width: 100%;
            height: 38px;
            border: 1px solid #d82229;
            color: #d82229;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            text-decoration: none;
            font-size: 14px;
            img{
                display: block;
                margin-right: 5px;
            }
            >span{
                margin-left: 5px;
            }

        }
        .contact1{
            cursor: pointer;
        }
    }
    .info{
        margin-top: 10px;
        h2{
            font-weight: 100;
            font-size: 14px;
            color: #fff;
            padding-left:35px;
            line-height: 30px;
            background: url("../../assets/icon/tixing.png") no-repeat;
            background-position: 11px 8px;
        }
        p{
            width: 168px;
            border-bottom:1px dashed #df6064;
            font-size: 12px;
            margin: 0 auto;
            color:#fff;
            line-height: 24px;
        }
        :last-child{
            border: none;
        }
    }
}

//以下弹窗样式
.phone{
    width: 100%;
    height: 38px;
    border-radius: 3px;
    color: #333;
    margin-top:20px;
    background: #d82229;
    line-height: 38px;
    text-align: center;
    color: #fff;
    display: flex;
    justify-content: center;;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    img{
        display: block;
        margin-right: 5px;
    }
    >span{
        margin-left: 5px;
    }

}

.phonePop{
  /deep/ .el-dialog{
    width:440px;
    height:220px;
    margin-top:40vh !important;
    .el-dialog__body{
        padding:0;
    }
    .el-dialog__header{
        background: #f5f5f5;
    }
    .popTop{
        height:120px;
        .storeName{
        font-size:22px;
        color:#333;
        height:44px;
        line-height:44px;
        background: #f5f5f5;
        text-align: center;
        }
        .storeMobile{
        height:66px;
        line-height: 66px;
        font-size:28px;
        color:#333;
        background: #f5f5f5;
        >div{
            width:280px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin:0 auto;
            padding:0 10px;
            overflow: hidden;
            color:#333333;
            img{
            display: block;
            }
        }
        .streFooter{
            height: 80px;
            font-size: 12px;
            color: #999;
            display: flex;
            justify-content: center;
            line-height: 24px;
        }
        }
    }

    }
    }
</style>
