<template>
    <div class="detailNav">
        <div class="search_content-wrap">

            <div class="search_content flex align-center">

                <img @click="$router.push('/')" alt="" src="../../assets/icon/ad.png">
                <img alt="" src="../../assets/icon/shuxian1.png" style="margin:0 60px">


                <div class="shopAd-content">
                    <div class="shopAd-content-left">
                        <img :src="store.logoUrl | imagePath" @click="goshop()" alt="" style="cursor:pointer;">
                    </div>
                    <div class="shopAd-content-right">
                        <div class="opens">
                            <span @click="goshop()" style="cursor:pointer;">{{store.storeName}}</span>

                            <span v-if="store.sellerRank==3">v3</span>
                            <span v-if="store.sellerRank==2">v2</span>
                            <span v-if="store.sellerRank==1">v1</span>
                            <span>
                    <img alt="" src="../../assets/icon/ying.png">
                </span>
                            <span>
                    <img alt="" src="../../assets/icon/renzheng.png" v-if="store.isPay">
                </span>
                        </div>
                        <div class="address">
                             <span>
                                <img alt="" src="../../assets/icon/location.png">
                             </span>
                             <span>
                                {{store.address}}
                             </span>
                        </div>
                    </div>
                </div>
                <!-- <img src="../../assets/icon/shuxian1.png" alt="" style="margin:0 20px">
                <img src="../../assets/icon/xiehui.png" alt=""> -->

                <div class="search flex-y flex-1">
                    <div class="search_input flex">


                        <el-autocomplete
                                :fetch-suggestions="querySearchAsync"
                                @input="inputValChange"
                                @keyup.enter.native="queryAdminRole"
                                @select="handleSelect"
                                placeholder="搜索 产品/店铺 下单即送等额礼品"
                                popper-class="aaa"
                                v-model="state"
                        ></el-autocomplete>


                        <div
                                @click="search(state)"
                                class="btn_search flex align-center flex-center"
                                style="background：#333 ！important"
                        >搜索
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import {mapMutations} from 'vuex'

    export default {
        name: "detailNav",
        data() {
            return {
                state: '',
                storeId: this.$route.query.sid,
                store: {},
            }
        },
        computed: {
            // ...mapGetters(['store'])
        },
        mounted() {
            this.inputValChange(); //调用搜索框
            this.query()
        },
        created() {
        },
        methods: {
            ...mapMutations({saveStore: 'STORE_INFO'}),
            query() {
                this.$http.get('store/info', {params: {storeId: this.storeId}}).then(res => {
                    const {data} = res.data;
                    this.store = data;
                    const {storeCustomerServiceList} = data;
                    this.callLists = storeCustomerServiceList || [];

                    this.saveStore(this.store)
                    // console.log(this.store)
                })
            },
            inputValChange() { //搜索框输入事件
                let self = this;
                if (self.state == "") {
                    this.restaurants = [];
                    return;
                }
                // debugger
                this.$http.get('/solr/suggest/list', {params: {keyWord: this.state}}).then(res => {

                    let arr = res.data.data;
                    let arr1 = [];
                    for (let i = 0; i < arr.length; i++) {
                        let obj = {
                            id: i,
                            value: arr[i].NAME,
                            cateId: arr[i].cateId,
                            state: arr[i].state,
                        };
                        arr1.push(obj)
                    }
                    // debugger
                    this.state1 = this.state;
                    let obj1 = {id: -1, value: "搜索\"" + this.state + "\"相关店铺"};
                    arr1.unshift(obj1);
                    this.restaurants = arr1;
                    // console.log( this.restaurants)


                })
            },

            queryAdminRole() { //点击搜索到的列表触发的事件
                if (this.state == "") {
                    this.$message({
                        message: '输入内容不能为空！',
                        type: 'warning'
                    });
                    return;
                }
                this.$router.push({
                    path: "/searchProduct",
                    query: {
                        val: this.state
                    }
                });
                let oSeclect = document.getElementsByClassName("el-autocomplete-suggestion")[0];
                oSeclect.style.display = "none";
                // this.state="";
            },
            search(state) { //精准搜索
                // console.log(444)
                // this.state="";
                if (state == "" || state.indexOf(" ") != -1) {
                    this.$message({
                        message: '输入内容不能为空！',
                        type: 'warning'
                    });
                    return;
                }
                this.$router.push({
                    path: "/searchProduct",
                    query: {
                        val: state
                    }
                })
            },
            querySearchAsync(queryString, cb) { //组件自带的搜索方法
                var restaurants = this.restaurants;
                var results = restaurants;
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    cb(results);
                }, 0 * Math.random());
            },
            handleSelect(item) { //精准搜索
                // if (item) {

                // }
                if (item.id == -1) {
                    this.$router.push({
                        path: "/searchProduct",
                        query: {
                            val: this.state1,
                            cateId: item.cateId,
                            cateState: item.state,
                            shopFlag: 1 //点击店铺
                        }
                    });
                    this.state = "";
                } else {
                    this.$router.push({
                        path: "/searchProduct",
                        query: {
                            val: this.state1,
                            cateId: item.cateId,
                            cateState: item.state
                        }

                    });
                    this.state = "";
                }
            },
            goshop() { //首页列表进店铺
                this.$router.push({
                    name: "shopPage",
                    params: {
                        id: this.store.id
                    }
                })
            },
        },
    }
</script>

<style lang="scss" scope="">
    * {
        margin: 0;
        padding: 0;
    }

    .detailNav {
        position: fixed;
        top: 34px;
        left: 0;
        right: 0;
        z-index: 100;
        border-bottom: 2px solid #d82229;
        background: #fff;

        .shopAd-content { //左侧主体
            width: 324px;
            height: 70px;
            display: flex;
            justify-content: flex-start;

            .shopAd-content-left {
                margin-right: 40px;

                img {
                    display: block;
                    width: 70px;
                    height: 70px;
                }
            }

            .shopAd-content-right {

                .opens {
                    height: 30px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    span {
                        margin-right: 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        display: block;

                        img {
                            display: block;
                        }
                    }

                    > :nth-child(1) {
                        max-width: 170px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        flex-shrink: 0;
                        font-size: 18px;
                        color: #333;
                        font-weight: 900;
                    }

                    :nth-child(2) {
                        width: 20px;
                        height: 20px;
                        text-align: center;
                        line-height: 20px;
                        font-size: 14px;
                        color: #fff;
                        background: #ffac00;
                        border-radius: 2px;
                        // padding:2px;
                    }

                    :nth-child(5) {
                        margin-right: 6px;
                        font-size: 14px;
                        color: #666;
                        margin-right: 0;
                    }

                    :nth-child(6) {
                        font-size: 14px;
                        margin: 0 10px;
                        color: #666;
                    }

                    :nth-child(8), :nth-child(9), :nth-child(10) {
                        font-size: 14px;
                        color: #666;
                        margin-right: 12px;
                    }
                }

                .adress {
                    margin-top: 10px;
                    height: 30px;
                    // background: orange;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .adress-title {
                        font-size: 14px;
                        color: #666;
                        margin-right: 12px;
                    }

                    .adress-list {
                        font-size: 12px;
                        color: #999;

                        span {
                            padding: 5px 6px;
                            margin-right: 14px;
                            border: 1px solid #e5e5e5;
                            border-radius: 2px;
                        }

                        :last-child {
                            margin-right: 0;

                        }
                    }

                    > :last-child {
                        width: 240px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        margin-left: 10px;
                        font-size: 14px;
                    }
                }

            }

        }


        .search_content {
            height: 100px;
            margin: 0 auto;
            // margin: 20px auto 10px;
            width: 100%;
            width: 1200px;
            background: #fff;
            margin: 0 auto;


            img {
                // width: 230px;
                // width: 404px;
            }

            .search {
                margin-right: 40px;
                margin-left: 119px;

                .search_input {
                    flex: 1;
                    height: 40px;

                    input {
                        flex: 1;
                        height: 34px;
                        -webkit-tap-highlight-color: none;
                        outline: none;
                        border: none;
                        padding: 0 20px;
                        font-size: 14px;
                        height: 100%;
                        box-sizing: border-box;
                        border: solid 2px #d82229;
                        // border-right: solid 1px #fff;
                        // border-top-left-radius: 20px;
                        // border-bottom-left-radius:20px;
                    }

                    .btn_search {
                        height: 100%;
                        background: #d82229;
                        width: 96px;
                        text-align: center;
                        color: #fff;
                        cursor: pointer;
                        font-weight: 500;
                        // border-top-right-radius:20px;
                        // border-bottom-right-radius:20px;
                    }

                }

                .keywords {
                    font-size: 12px;
                    color: #999999;
                    margin-top: 8px;

                    span {
                        padding: 0 10px 0 0;
                        position: relative;
                        margin-right: 10px;
                        cursor: pointer;

                        &::after {
                            position: absolute;
                            content: '/';
                            font-weight: 500;
                            right: 0;
                            top: 2px;
                        }
                    }
                }
            }

            .btn_send_need {
                width: 120px;
                height: 40px;
                background-color: #333;
                // border-radius: 20px;
                color: #fff;
                font-weight: 500;
                margin-bottom: 24px;
                cursor: pointer;
                // margin-right: 20px;
                flex-shrink: 0;
            }
        }
    }
</style>